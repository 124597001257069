import ProductList from "@/pages/product/productList.vue";
import ProductDetail from "@/pages/product/productDetail.vue";
import ProductAdd from "@/pages/product/productAdd.vue";
import ProductUpdate from "@/pages/product/productUpdate.vue";
import ProductDelete from "@/pages/product/productDelete.vue";

let routes = [
  {
    path: "/productList",
    name: "productList",
    component: ProductList,
  },
  {
    path: "/productDetail",
    name: "productDetail",
    component: ProductDetail,
  },
  {
    path: "/productAdd",
    name: "productAdd",
    component: ProductAdd,
  },
  {
    path: "/productUpdate",
    name: "productUpdate",
    component: ProductUpdate,
  },
  {
    path: "/productDelete",
    name: "productDelete",
    component: ProductDelete,
  },
];

export default routes;
