<template>
  <el-container class="contentWrapper">
    <el-header height="80px" style="border: 1px solid #eceef3">
      <Header />
    </el-header>
    <el-container style="height: calc(100% - 80px)">
      <el-aside class="contentLeft" width="400px">
        <div class="formTitle">输入参数</div>
        <el-scrollbar class="formScroll">
          <el-form
            class="formWrapper"
            :model="formData"
            ref="argumentForm"
            :rules="rules"
            label-position="top"
            @submit.native.prevent
          >
            <el-form-item prop="Id" label="Id 会员Id">
              <el-input
                v-model="formData.Id"
                placeholder="请输入整型数字"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div class="formFooter">
          <el-button @click="resetForm">清空</el-button>
          <el-button type="primary" @click="submitForm">发起调用</el-button>
        </div>
      </el-aside>
      <div class="tabsWrapper">
        <el-tabs v-model="tabsActive" @tab-click="tabsChange">
          <el-tab-pane name="document" label="文档">
            <el-scrollbar style="height: 100%">
              <RequestMethod method="POST" address="/OpenCustomer/Delete" />
              <div class="tableTitle">请求参数</div>
              <el-table :data="requestTableData" :border="true">
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="isRequired" label="必填" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
              <div class="tableTitle">响应参数</div>
              <el-table
                class="lastTable"
                :data="responseTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="result" label="调用结果">
            <el-scrollbar style="height: 100%">
              <pre
                v-show="responseData"
                v-highlight
                :key="timestep"
              ><code class="json" v-html="responseData"></code></pre>
              <div v-show="!responseData">
                <el-empty description="暂无调用结果"></el-empty>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="history" label="调用历史">
            <History ref="history" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { customerDelete } from "@/api/index.js";
import Header from "@/components/header.vue";
import History from "@/components/history.vue";
import RequestMethod from "@/components/requestMethod.vue";
export default {
  components: {
    Header,
    History,
    RequestMethod,
  },
  data() {
    return {
      formData: {
        Id: "",
      },
      rules: {
        Id: [{ required: true, message: "请输入会员Id", trigger: "blur" }],
      },
      tabsActive: "document",
      requestTableData: [
        {
          name: "Id",
          type: "Integer",
          isRequired: "是",
          description: "会员Id，支持输入多个Id用半角逗号(,)分割。",
          example: "1,2",
        },
      ],
      responseTableData: [
        {
          name: "Data",
          type: "Boolean",
          description: "删除是否成功",
          example: "true",
        },
        {
          name: "IsSuccess",
          type: "Boolean",
          description: "请求是否成功",
          example: "true",
        },
        {
          name: "Msg",
          type: "String",
          description: "请求失败信息",
          example: "",
        },
        {
          name: "RequestId",
          type: "String",
          description: "请求ID",
          example: "",
        },
      ],
      responseData: "",
      timestep: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs["argumentForm"].validate((valid) => {
        if (valid) {
          let data = this.formData.Id.split(",");
          customerDelete(data)
            .then((res) => {
              this.tabsActive = "result";
              this.timestep = Date.parse(new Date());
              this.responseData = res;
              if (res.IsSuccess) {
                this.$message({
                  message: "调用成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["argumentForm"].resetFields();
    },
    tabsChange() {
      if (this.tabsActive == "history") {
        this.$refs["history"].getApiHistory();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less"></style>
