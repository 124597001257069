<template>
  <el-scrollbar class="historyWrapper">
    <el-table :data="tableData">
      <el-table-column
        prop="CreateTime"
        label="调用时间"
        :formatter="formatter"
      >
      </el-table-column>
      <el-table-column prop="CallerIp" label="调用IP"> </el-table-column>
      <el-table-column prop="IsSuccess" label="调用状态">
        <template slot-scope="scope">
          <div v-if="scope.row.IsSuccess">
            <i
              class="el-icon-success"
              style="color: #67c23a; margin-right: 4px"
            ></i
            >成功
          </div>
          <div v-else>
            <i
              class="el-icon-error"
              style="color: #f56c6c; margin-right: 4px"
            ></i
            >失败
            <div>{{ scope.row.ErrorMsg }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      :current-page="pageIndex"
      :page-size="pageSize"
      :total="totalCount"
      :page-count="totalPageCount"
      @current-change="handleCurrentChange"
      style="text-align: right"
    >
    </el-pagination>
  </el-scrollbar>
</template>

<script>
import { getApiCallHistory } from "@/api/index.js";
import { formatTime } from "@/utils/index.js";
export default {
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 1,
      totalPageCount: 1,
      routeNameToApiNameObj: {
        newsList: "GetNewsList",
        newsDetail: "GetNewsDetail",
        newsAdd: "CreateArticle",
        newsUpdate: "EditArticle",
        newsDelete: "DeleteArticle",
        productList: "GetProductList",
        productDetail: "GetProductDetail",
        productAdd: "CreateProduct",
        productUpdate: "EditProduct",
        productDelete: "DeleteProducts",
        customerList: "GetCustomerList",
      },
    };
  },
  methods: {
    getApiHistory() {
      getApiCallHistory({
        ApiCalledName: this.routeNameToApiNameObj[this.$route.name],
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        AccessId: this.$store.state.AccessId,
      })
        .then((res) => {
          if (res.IsSuccess) {
            this.tableData = res.Data.List;
            this.totalCount = res.Data.TotalCount;
            this.totalPageCount = res.Data.TotalPageCount;
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatter(row) {
      return formatTime(row.CreateTime, "yyyy-MM-dd HH:mm:ss");
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getApiHistory();
    },
  },
};
</script>
<style lang="less">
.historyWrapper {
  height: 100%;
  .el-table {
    margin-top: 24px;
  }
}
</style>
