import PictureList from "@/pages/picture/pictureList.vue";
import PictureDetail from "@/pages/picture/pictureDetail.vue";
import PictureAdd from "@/pages/picture/pictureAdd.vue";
import PictureUpdate from "@/pages/picture/pictureUpdate.vue";
import PictureDelete from "@/pages/picture/pictureDelete.vue";

let routes = [
  {
    path: "/pictureList",
    name: "pictureList",
    component: PictureList,
  },
  {
    path: "/pictureDetail",
    name: "pictureDetail",
    component: PictureDetail,
  },
  {
    path: "/pictureAdd",
    name: "pictureAdd",
    component: PictureAdd,
  },
  {
    path: "/pictureUpdate",
    name: "pictureUpdate",
    component: PictureUpdate,
  },
  {
    path: "/pictureDelete",
    name: "pictureDelete",
    component: PictureDelete,
  },
];

export default routes;
