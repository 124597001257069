import axios from 'axios' // 引入
import { Message } from 'element-ui';
import store from '../store/index';

// 这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=='development'){
//   baseURL=''
// }else{
//   baseURL=''
// }
let config = {
    baseURL: "",
    headers:{
        AccessId: "",
        AccessKey: ""
    },
    // 因为跨域了，所以这里如果写的话会自动拼接，会有两份，所以隐藏了
    timeout: 30000 // 设置最大请求时间
}

let _axios = axios.create(config)

// /* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    config => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        if(store.state.secondDomain == ""){
            source.cancel("请填写站点域名")
            return config
        }else{
            config.baseURL = store.state.secondDomain;
        }
        if(store.state.AccessId == ""){
            source.cancel("请填写AccessId")
            return config
        }else{
            config.headers.AccessId = store.state.AccessId;
        }
        if(store.state.AccessKey == ""){
            source.cancel("请填写AccessKey")
            return config
        }else{
            config.headers.AccessKey = store.state.AccessKey;
        }
        return config
    },
    err => Promise.reject(err)
)

// /* 请求之后的操作 */
_axios.interceptors.response.use(
    res => {
        // 在这里关闭请求时的loading动画效果
        // 这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
        // 一定结合自己的后端的返回代码进行操作
        // if (res.data.code === 996) {
            
        // } else if (res.data.code !== 200) {
        //     ElMessage.error(res.data.message)
        // }
        return res
    },
    err => {
        if (err) {
            if(err.name == "CanceledError"){
                Message.error(err)
            }else{
                Message.error("请检查站点域名、AccessId、AccessKey是否正确")
            }
        }
        return Promise.reject(err)
    }
)

// 封装post,get方法
// 按理来说应该也可以封装其他的方法
const http = {
    get(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios({
                url,
                params,
                method: 'GET'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    post(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                method: 'POST'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    }
}

// 暴露所写的内容
export default http
