<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="homeWrapper">
      <div class="title">应用模板</div>
      <div class="updateTime">更新时间：2024-04-07 14:25</div>
      <div class="content">
        <div>本文描述了镜像市场的应用的安装和使用。</div>
        <div>
          点击<strong>开发者模式 > 应用模板</strong
          >进入到镜像市场，支持用户根据需要进行安装使用，或下载后二次编辑后打包上传进行使用，同时支持同一个应用的多个版本之间的自由更新。
        </div>
        <div><strong>操作说明</strong></div>
        <div>
          1.<strong>安装</strong>：找到目标应用，点击<strong>安装</strong>，显示安装进度条，成功后，安装后的应用显示在<a
            href="myApp"
            >我的应用</a
          >中。
        </div>
        <img class="contentImg" src="@/assets/image/appTemplate1.png" />
        <div>
          2.<strong>下载</strong>：点击“<strong>下载</strong>”跳转到<strong>GitHub</strong>当前应用下载页面。
        </div>
        <div>
          3.<strong>更新</strong>：安装过的应用，均显示“更新”按钮，点击“<strong>更新</strong>”按钮，显示可更新的所有版本，支持跨版本及历史版本的随意更新。
        </div>
        <img class="contentImg" src="@/assets/image/appTemplate2.png" />
        <div><strong>应用说明</strong></div>
        <img class="contentImg" src="@/assets/image/appTemplate3.png" />
        <div>
          1.<strong>微站镜像</strong>：网站的渲染服务,ECS独立IP版,支持异常访问拦截/流控等新功能,底层采用最新的.netcore框架,跨平台,支持Linux/Window服务器。
        </div>
        <div>
          2.<strong>Pages Browser</strong
          >：可以方便快捷的上传/在线编辑/浏览的静态页面,就像一款在线的IIS,安装后用默认初始账号为“pagesadmin”，密码为“changethispwd”，登录后即可上传页面。
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.homeWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .contentImg {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 24px;
  }
  .content div {
    font-size: 14px;
    color: #181818;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0 0 16px;
    word-break: break-word;
  }
  strong {
    font-weight: bold;
  }
  .contentList {
    padding-left: 25px;
    li {
      list-style-type: disc;
      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
  a {
    color: #409eff;
  }
}
</style>
