<template>
  <div>
    <div class="tableTitle">请求方法</div>
    <el-descriptions :column="1" :border="true">
      <el-descriptions-item label="请求方法">{{ method }}</el-descriptions-item>
      <el-descriptions-item label="接口地址">{{
        address
      }}</el-descriptions-item>
      <el-descriptions-item label="接口baseurl">{{
        secondDomain
      }}</el-descriptions-item>
      <el-descriptions-item label="鉴权方法">
        请求header中添加 AccessId:{{
          $store.state.AccessId ? $store.state.AccessId : "您的AccessId"
        }}, AccessKey:{{ AccessKey }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: ["method", "address"],
  data() {
    return {};
  },
  computed: {
    secondDomain() {
      let domain = "";
      if (
        this.$store.state.secondDomain &&
        this.$store.state.secondDomain != "//"
      ) {
        domain = this.$store.state.secondDomain;
      } else {
        domain = "站点域名";
      }
      return domain;
    },
    AccessKey() {
      let key = "";
      if (this.$store.state.AccessKey.length) {
        for (let i = 0; i < this.$store.state.AccessKey.length; i++) {
          key = key + "*";
        }
      } else {
        key = "您的AccessKey";
      }
      return key;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less"></style>
