<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="homeWrapper">
      <div class="title">AccessKey管理</div>
      <div class="updateTime">更新时间：2024-04-07 14:25</div>
      <div class="content">
        <div>网站对外数据联动时的AccessId & AccessKey的生产及使用说明。</div>
        <div>
          1.点击<strong>新建AccessKey</strong>自动创建一条新的<strong
            >AccessId</strong
          >
          & <strong>AccessKey</strong>。
        </div>
        <img class="contentImg" src="@/assets/image/akManage1.png" />
        <div>
          2.点击<strong>调用API</strong>跳转到API调用页面，详见<a
            href="accessGuide"
            >通用接口</a
          >。
        </div>
        <div>
          3.点击<strong>一键复制</strong>自动复制当前的<strong>AccessId</strong>&<strong
            >AccessKey</strong
          >
          用以目标调用。
        </div>
        <div>4.点击<strong>调用历史</strong>可以查看调用的IP、时间、结果。</div>
        <img class="contentImg" src="@/assets/image/akManage2.png" />
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.homeWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .contentImg {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 24px;
  }
  .content div {
    font-size: 14px;
    color: #181818;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0 0 16px;
    word-break: break-word;
  }
  strong {
    font-weight: bold;
  }
  .contentList {
    padding-left: 25px;
    li {
      list-style-type: disc;
      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
  a {
    color: #409eff;
  }
  .tipsWrapper {
    display: flex;
    background-color: #f5f5f6;
    border-left: 2px solid #409eff;
    padding: 16px 0 0 16px;
    i {
      font-size: 16px;
      color: #409eff;
    }
  }
}
</style>
