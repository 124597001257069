<template>
  <el-container class="contentWrapper">
    <el-header height="80px" style="border: 1px solid #eceef3">
      <Header />
    </el-header>
    <el-container style="height: calc(100% - 80px)">
      <el-aside class="contentLeft" width="400px">
        <div class="formTitle">
          输入参数
          <div>
            <el-switch v-model="isCompleteMessage" @change="switchChange">
            </el-switch>
            <span class="switchText">完整报文</span>
          </div>
        </div>
        <el-scrollbar class="formScroll">
          <el-form
            class="formWrapper"
            v-show="!isCompleteMessage"
            :model="formData"
            ref="argumentForm"
            :rules="rules"
            label-position="top"
          >
            <el-form-item prop="Title" label="Title 文章标题">
              <el-input
                v-model="formData.Title"
                placeholder="请输入字符串"
              ></el-input>
            </el-form-item>
            <el-form-item prop="ContentDetail" label="ContentDetail 文章详情">
              <el-input
                v-model="formData.ContentDetail"
                placeholder="请输入字符串"
                type="textarea"
                :rows="3"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="completeWrapper" v-show="isCompleteMessage">
            <div class="completeTitle">NewsItemModel</div>
            <el-input
              v-model="completeformData"
              type="textarea"
              rows="20"
              placeholder="请输入参数对象"
            ></el-input>
          </div>
        </el-scrollbar>
        <div class="formFooter">
          <el-button @click="resetForm">清空</el-button>
          <el-button type="primary" @click="submitForm">发起调用</el-button>
        </div>
      </el-aside>
      <div class="tabsWrapper">
        <el-tabs v-model="tabsActive" @tab-click="tabsChange">
          <el-tab-pane name="document" label="文档">
            <el-scrollbar style="height: 100%">
              <RequestMethod method="POST" address="/OpenNews/Add" />
              <div class="tableTitle">请求参数</div>
              <el-table
                :data="requestTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="isRequired" label="必填" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
              <div class="tableTitle">响应参数</div>
              <el-table
                class="lastTable"
                :data="responseTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="result" label="调用结果">
            <el-scrollbar style="height: 100%">
              <pre
                v-show="responseData"
                v-highlight
                :key="timestep"
              ><code class="json" v-html="responseData"></code></pre>
              <div v-show="!responseData">
                <el-empty description="暂无调用结果"></el-empty>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="history" label="调用历史">
            <History ref="history" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { newsAdd } from "@/api/index.js";
import Header from "@/components/header.vue";
import History from "@/components/history.vue";
import RequestMethod from "@/components/requestMethod.vue";
import { formatTime } from "@/utils/index.js";
export default {
  components: {
    Header,
    History,
    RequestMethod,
  },
  data() {
    return {
      isCompleteMessage: false,
      formData: {
        Title: "",
        ContentDetail: "",
        CreatedDatetime: "",
      },
      completeformData: `{
    "Id": 0,
    "Title": "",
    "Summary": null,
    "ContentDetail": "",
    "CategoryId": -5,
    "CreatedDatetime": "",
    "MetaKeywords": null,
    "SeoFriendName": null,
    "MetaDescription": null,
    "IsAllowComments": true,
    "IsAllowAcl": false,
    "IsToped": false,
    "Published": true,
    "SearchKeywords": null,
    "PictureUrl": null,
    "PictureId": null,
    "RichTextTemplateId": 0
}`,
      defaultData: {
        Id: 0,
        Title: "",
        Summary: null,
        ContentDetail: "",
        CategoryId: -5,
        CreatedDatetime: "",
        MetaKeywords: null,
        SeoFriendName: null,
        MetaDescription: null,
        IsAllowComments: true,
        IsAllowAcl: false,
        IsToped: false,
        Published: true,
        SearchKeywords: null,
        PictureUrl: null,
        PictureId: null,
        RichTextTemplateId: 0,
      },
      rules: {
        Title: [{ required: true, message: "请输入Title", trigger: "blur" }],
        ContentDetail: [
          { required: true, message: "请输入ContentDetail", trigger: "blur" },
        ],
      },
      tabsActive: "document",
      requestTableData: [
        {
          name: "NewsItemModel",
          type: "",
          isRequired: "",
          description: "",
          example: "",
          children: [
            {
              name: "Title",
              type: "String",
              isRequired: "是",
              description: "文章标题",
              example: "",
            },
            {
              name: "ContentDetail",
              type: "String",
              isRequired: "是",
              description: "文章内容详情",
              example: "",
            },
            {
              name: "CreatedDatetime",
              type: "String",
              isRequired: "是",
              description: "文章创建时间",
              example: "",
            },
            {
              name: "CategoryId",
              type: "Integer",
              isRequired: "否",
              description: "分类Id",
              example: "",
            },
            {
              name: "Summary",
              type: "String",
              isRequired: "否",
              description: "文章简介",
              example: "",
            },
            {
              name: "Published",
              type: "Boolean",
              isRequired: "否",
              description: "文章是否发布",
              example: "",
            },
            {
              name: "IsToped",
              type: "Boolean",
              isRequired: "否",
              description: "文章是否置顶",
              example: "",
            },
            {
              name: "IsDeleted",
              type: "Boolean",
              isRequired: "否",
              description: "文章是否删除",
              example: "",
            },
            {
              name: "IsAllowComments",
              type: "Boolean",
              isRequired: "否",
              description: "文章是否允许评论",
              example: "",
            },
            {
              name: "IsAllowAcl",
              type: "Boolean",
              isRequired: "否",
              description: "文章访问权限是否开启",
              example: "",
            },
            {
              name: "SelectedCustomerRoleIds",
              type: "Object []",
              isRequired: "否",
              description: "文章访问权限选中id数组",
              example: "",
            },
            {
              name: "MetaKeywords",
              type: "String",
              isRequired: "否",
              description: "文章SEO关键词",
              example: "",
            },
            {
              name: "MetaDescription",
              type: "String",
              isRequired: "否",
              description: "文章SEO描述",
              example: "",
            },
            {
              name: "SearchKeywords",
              type: "String",
              isRequired: "否",
              description: "文章搜索关键词",
              example: "",
            },
            {
              name: "PictureUrl",
              type: "String",
              isRequired: "否",
              description: "文章封面图片地址",
              example: "",
            },
            {
              name: "PictureId",
              type: "String",
              isRequired: "否",
              description: "文章封面图片Id",
              example: "",
            },
            {
              name: "MimeType",
              type: "String",
              isRequired: "否",
              description: "文章封面图片类型",
              example: "",
            },
            {
              name: "RichTextTemplateId",
              type: "Integer",
              isRequired: "否",
              description: "富文本模版Id",
              example: "",
            },
          ],
        },
      ],
      responseTableData: [
        {
          name: "Data",
          type: "Integer",
          description: "文章Id",
          example: "",
        },
        {
          name: "IsSuccess",
          type: "Boolean",
          description: "请求是否成功",
          example: "true",
        },
        {
          name: "Msg",
          type: "String",
          description: "请求失败信息",
          example: "",
        },
        {
          name: "RequestId",
          type: "String",
          description: "请求ID",
          example: "",
        },
      ],
      responseData: "",
      timestep: "",
    };
  },
  methods: {
    switchChange() {
      if (this.isCompleteMessage) {
        let completeformDataObj = new Function(
          "return" + this.completeformData
        )();
        if (this.formData.Title) {
          completeformDataObj.Title = this.formData.Title;
        }
        if (this.formData.ContentDetail) {
          completeformDataObj.ContentDetail = this.formData.ContentDetail;
        }
        completeformDataObj.CreatedDatetime = formatTime(
          new Date(),
          "yyyy-MM-dd HH:mm:ss"
        );
        this.completeformData = JSON.stringify(completeformDataObj, null, 2);
      }
    },
    submitForm() {
      if (this.isCompleteMessage) {
        let completeformDataObj = new Function(
          "return" + this.completeformData
        )();
        newsAdd(completeformDataObj)
          .then((res) => {
            this.tabsActive = "result";
            this.timestep = Date.parse(new Date());
            this.responseData = res;
            if (res.IsSuccess) {
              this.$message({
                message: "调用成功",
                type: "success",
              });
            } else {
              this.$message.error(res.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$refs["argumentForm"].validate((valid) => {
          if (valid) {
            this.formData.CreatedDatetime = formatTime(
              new Date(),
              "yyyy-MM-dd HH:mm:ss"
            );
            let data = {
              ...this.defaultData,
              ...this.formData,
            };
            newsAdd(data)
              .then((res) => {
                this.tabsActive = "result";
                this.timestep = Date.parse(new Date());
                this.responseData = res;
                if (res.IsSuccess) {
                  this.$message({
                    message: "调用成功",
                    type: "success",
                  });
                } else {
                  this.$message.error(res.Msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    resetForm() {
      this.$refs["argumentForm"].resetFields();
    },
    tabsChange() {
      if (this.tabsActive == "history") {
        this.$refs["history"].getApiHistory();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped></style>
