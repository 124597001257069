<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="accessGuideWrapper">
      <div class="title">通用接口</div>
      <div class="updateTime">更新时间：2024-04-07 14:25</div>
      <div>网站开放API接口说明页面。</div>
      <div class="tipsWrapper">
        <div style="margin-right: 12px">
          <i class="el-icon-warning-outline"></i>
          <strong style="margin-left: 4px">说明</strong>
        </div>
        <div class="tips">
          通用接口云梦API开放平台是基于云梦建站技术实践经验，面向云梦建站用户及合作伙伴，提供高易用的API服务。目前适用于“云·速成美站”、“云·企业官网”内的文章、产品的增、删、改、查操作，旨在帮助用户加速创建网站的文章、产品。
          本产品（云·速成美站、云·企业官网）的OpenAPI采用RESTful接口请求方式，请求方法详见api右侧。在使用API前，您需要登录到您的产品后台-网站管理-AccessKey管理，新建AccessKey后直接调用API，或复制到云梦API开放平台（<a href="https://open-api.clouddream.net" style="color: #409eff;" target="_blank">https://open-api.clouddream.net</a>），粘贴所复制的站点域名、AccessId、AccessKey，即可鉴权通过访问API。
        </div>
      </div>
      <div style="font-size: 24px; font-weight: bold">接入指南</div>
      <div class="summary">
        “云梦智能开放API”通过API接口对外开放数据传输，使得第三方开发者可以方便地将其集成到自己的应用程序或系统中，提高数据共享效率。以下为对接微信公众号应用场景示例。
      </div>
      <ul class="stepWrapper">
        <li class="stepItem">
          <div class="stepTitle">一. 接入准备</div>
          <ul class="secondWrapper">
            <li>
              <div class="secondTitle">1. 获取微站的AK/SK</div>
              <div class="contentWrapper">
                <div class="content">
                  <div class="point"></div>
                  登录到您的产品后台，选择
                  <strong>网站管理 > AccessKey管理</strong>。
                </div>
                <img class="contentImg" src="@/assets/image/accessGuide1.png" />
                <div class="content" style="margin-top: 48px">
                  <div class="point"></div>
                  进入 <strong>AccessKey管理</strong>，点击
                  <strong>新建AccessKey</strong>，点击
                  <strong>调用api</strong>图标，进入
                  <a href="https://open-api.clouddream.net" target="_blank"
                    >云梦API开放平台</a
                  >
                  ，粘贴所复制的站点域名、AccessId、AccessKey，即可鉴权通过访问API。
                </div>
                <img class="contentImg" src="@/assets/image/accessGuide2.png" />
                <img
                  class="contentImg imgBorder"
                  src="@/assets/image/accessGuide3.png"
                />
              </div>
            </li>
            <li style="margin-top: 60px">
              <div class="secondTitle">2. 获取微信公众号的身份认证</div>
              <div class="contentWrapper">
                <div class="thirdTitle">2.1 获取微信AppID和AppSecret</div>
                <div class="content">
                  <div class="point"></div>
                  AppID和AppSecret可在
                  <strong>微信公众平台 > 开发 > 基本配置</strong>
                  页中获得（需要已经成为开发者，且账号没有异常状态）。
                </div>
                <img
                  class="contentImg2 imgBorder"
                  src="@/assets/image/accessGuide4.png"
                />
                <div class="content">
                  <div class="point"></div>
                  调用接口时，请登录
                  <strong>微信公众平台 > 开发 > 基本配置</strong>
                  提前将服务器IP地址添加到IP白名单中，点击查看设置方法，否则将无法调用成功。
                </div>
              </div>
              <div class="contentWrapper">
                <div class="thirdTitle" style="margin-top: 60px">
                  2.2 获取 Access token
                </div>
                <div class="content">
                  <div class="point"></div>
                  access_token是公众号的全局唯一接口调用凭据，公众号调用各接口时都需使用access_token。
                </div>
                <div class="content contentList">
                  <div class="point"></div>
                  使用AppID和AppSecret调用本接口来获取access_token。
                </div>
                <div class="content contentList">
                  <div class="point"></div>
                  查看
                  <a
                    href="https://developers.weixin.qq.com/doc/offiaccount/Basic_Information/Get_access_token.html"
                    target="_blank"
                    >公众平台的API调用所需的access_token的使用及生成方式说明</a
                  >
                </div>
                <img
                  class="contentImg2 imgBorder"
                  src="@/assets/image/accessGuide5.png"
                />
              </div>
            </li>
          </ul>
        </li>
        <li class="stepItem">
          <div class="stepTitle">二. 调用说明</div>
          <ul class="secondWrapper">
            <li>
              <div class="secondTitle">1. 导入微站图片到微信永久图片素材</div>
              <div class="contentWrapper">
                <div class="content">
                  微站图片可以直接同步到微信公众号，方便快捷管理图文消息，并且图片永久使用。新增的永久素材也可以在公众平台官网素材管理模块中查询管理。
                </div>
                <div class="thirdTitle" style="font-size: 20px">使用方法</div>
                <div class="content">
                  <div class="point"></div>
                  具体调用步骤可参见下方提供的demo示例。
                </div>
                <div class="content contentList">
                  <div class="point"></div>
                  使用微信access_token访问新增永久素材接口，可查看
                  <a
                    href="https://developers.weixin.qq.com/doc/offiaccount/Asset_Management/Adding_Permanent_Assets.html"
                    target="_blank"
                    >接口使用方法</a
                  >
                </div>
                <img
                  class="contentImg2 imgBorder"
                  src="@/assets/image/accessGuide6.png"
                />
              </div>
            </li>
            <li>
              <div class="secondTitle" style="margin-top: 60px">
                2. 导入微站文章到微信并发布
              </div>
              <div class="contentWrapper">
                <div class="content">
                  微站文章也可以直接同步到微信公众号，直接在公众号管理图文消息。还支持正文中插入自己账号和其他公众号已群发文章链接的能力。
                </div>
                <div class="thirdTitle" style="font-size: 20px">使用方法</div>
                <div class="content" style="font-size: 16px; color: #333">
                  <strong>1. 新增文章到微信草稿</strong>
                </div>
                <div class="content">
                  <div class="point"></div>
                  具体调用步骤可参见下方提供的demo示例。
                </div>
                <div class="content contentList">
                  <div class="point"></div>
                  使用微信access_token访问新增草稿接口，可查看
                  <a
                    href="https://developers.weixin.qq.com/doc/offiaccount/Draft_Box/Add_draft.html"
                    target="_blank"
                    >接口使用方法</a
                  >
                </div>
                <img
                  class="contentImg2 imgBorder"
                  src="@/assets/image/accessGuide7.png"
                />
                <div
                  class="content"
                  style="font-size: 16px; margin-top: 32px; color: #333"
                >
                  <strong> 2. 发布微信草稿 </strong>
                </div>
                <div class="content">
                  <div class="point"></div>
                  开发者需要先将图文素材以草稿的形式保存（见
                  <strong>草稿箱 > 新建草稿</strong
                  >，如需从已保存的草稿中选择，见
                  <strong>草稿箱 > 获取草稿列表</strong>），选择要发布的草稿
                  media_id 进行发布。查看
                  <a
                    href="https://developers.weixin.qq.com/doc/offiaccount/Publish/Publish.html"
                    target="_blank"
                    >发布接口使用方法</a
                  >
                </div>
                <img
                  class="contentImg2 imgBorder"
                  src="@/assets/image/accessGuide8.png"
                />
              </div>
            </li>
          </ul>
        </li>
        <li class="stepItem">
          <div class="stepTitle">三. Demo摘要</div>
          <div class="stepTips">完整示例见下方github链接。</div>
          <Code ref="Code" />
        </li>
        <li class="stepItem">
          <div class="stepTitle">四. 示例代码下载</div>
          <div class="stepTips">
            我们提供了.NET版 API调用示例，开发者可下载使用。 下载地址：
            <a
              href="https://github.com/woxieao/WeZhanApiCallerDemo"
              target="_blank"
              >https://github.com/woxieao/WeZhanApiCallerDemo</a
            >
          </div>
          <img
            class="contentImg imgBorder"
            src="@/assets/image/accessGuide9.png"
          />
        </li>
      </ul>
    </div>
  </el-scrollbar>
</template>

<script>
import Code from "@/components/code.vue";
export default {
  components: { Code },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.accessGuideWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .tipsWrapper {
    display: flex;
    background-color: #f5f5f6;
    border-left: 2px solid #409eff;
    padding: 16px;
    margin: 16px 0;
    i {
      font-size: 16px;
      color: #409eff;
    }
    .tips {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.4px;
      line-height: 24px;
      word-break: break-word;
    }
  }
  .summary {
    font-size: 16px;
    color: #666;
    line-height: 24px;
    margin-top: 24px;
  }
  .imgBorder {
    border: 1px solid rgb(236, 238, 243);
  }
  .stepWrapper {
    margin-top: 40px;
    border-top: 1px solid #e6e8f3;
    .stepItem {
      margin-top: 40px;
      .stepTips {
        font-size: 14px;
        color: #666;
        line-height: 24px;
        margin-top: 16px;
        a {
          color: #409eff;
        }
      }
    }
    .stepTitle {
      font-size: 20px;
      font-weight: bold;
      color: #262626;
    }
    .secondWrapper {
      margin-top: 32px;
      .secondTitle {
        font-size: 16px;
        font-weight: bold;
        color: #262626;
      }
      .contentWrapper {
        .content {
          font-size: 14px;
          color: #666;
          line-height: 24px;
          margin-top: 16px;
          .point {
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #262626;
            vertical-align: middle;
            margin-top: -3px;
          }
          strong {
            font-weight: bold;
          }
          a {
            color: #409eff;
          }
        }
        .contentList {
          margin-top: 8px;
        }
        .thirdTitle {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-top: 32px;
        }
      }
    }
    .contentImg {
      width: 980px;
      margin-top: 16px;
    }
    .contentImg2 {
      width: 650px;
      margin-top: 16px;
    }
  }
}
</style>
