<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="leftWrapper">
      <el-menu
        :default-active="activeIndex"
        :default-openeds="openedList"
        @select="handlerSelect"
        @open="handlerOpen"
        @close="handlerOpen"
      >
        <el-menu-item index="/home" class="menuHome">
          <template slot="title">开发者模式</template>
        </el-menu-item>
        <el-menu-item index="/bindECS" class="menuHome secondMenu">
          <template slot="title">绑定ECS</template>
        </el-menu-item>
        <el-menu-item index="/appTemplate" class="menuHome secondMenu">
          <template slot="title">应用模板</template>
        </el-menu-item>
        <el-menu-item index="/myApp" class="menuHome secondMenu">
          <template slot="title">我的应用</template>
        </el-menu-item>
        <el-submenu index="/accessGuide">
          <template slot="title"
            ><div style="padding-left: 8px">通用接口</div></template
          >
          <el-submenu
            v-for="(item, index) in menuData"
            :key="index"
            :index="item.index"
          >
            <template slot="title">{{ item.label }}</template>
            <el-menu-item
              v-for="(el, i) in item.children"
              :key="i"
              :index="el.index"
            >
              <template slot="title">{{ el.label }}</template>
            </el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="/akManage" class="menuHome secondMenu">
          <template slot="title">AccessKey管理</template>
        </el-menu-item>
      </el-menu>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          label: "文章列表",
          index: "1",
          children: [
            {
              label: "获取文章列表",
              index: "/newsList",
            },
            {
              label: "获取文章详情",
              index: "/newsDetail",
            },
            {
              label: "创建文章",
              index: "/newsAdd",
            },
            {
              label: "编辑文章",
              index: "/newsUpdate",
            },
            {
              label: "删除文章",
              index: "/newsDelete",
            },
          ],
        },
        {
          label: "产品列表",
          index: "2",
          children: [
            {
              label: "获取产品列表",
              index: "/productList",
            },
            {
              label: "获取产品详情",
              index: "/productDetail",
            },
            {
              label: "创建产品",
              index: "/productAdd",
            },
            {
              label: "编辑产品",
              index: "/productUpdate",
            },
            {
              label: "删除产品",
              index: "/productDelete",
            },
          ],
        },
        {
          label: "会员中心",
          index: "3",
          children: [
            {
              label: "获取会员列表",
              index: "/customerList",
            },
            {
              label: "获取会员详情",
              index: "/customerDetail",
            },
            {
              label: "创建会员",
              index: "/customerAdd",
            },
            {
              label: "编辑会员",
              index: "/customerUpdate",
            },
            {
              label: "删除会员",
              index: "/customerDelete",
            },
          ],
        },
        {
          label: "图片管理",
          index: "4",
          children: [
            {
              label: "获取图片列表",
              index: "/pictureList",
            },
            {
              label: "获取图片详情",
              index: "/pictureDetail",
            },
            {
              label: "创建图片",
              index: "/pictureAdd",
            },
            {
              label: "编辑图片",
              index: "/pictureUpdate",
            },
            {
              label: "删除图片",
              index: "/pictureDelete",
            },
          ],
        },
      ],
    };
  },
  computed: {
    activeIndex() {
      return this.$route.path == "/" ? "/home" : this.$route.path;
    },
    openedList() {
      let list = ["/accessGuide"];
      let routeList = [
        "/",
        "/home",
        "/bindECS",
        "/appTemplate",
        "/myApp",
        "/accessGuide",
        "/akManage",
      ];
      if (!routeList.includes(this.$route.path)) {
        this.menuData.forEach((item) => {
          item.children.forEach((el) => {
            if (el.index == this.$route.path) {
              list.push(item.index);
            }
          });
        });
      }
      return list;
    },
  },
  methods: {
    handlerSelect(index) {
      if (this.$route.path == index) {
        return;
      }
      this.$router.push({
        path: index,
        query: { ...this.$route.query },
      });
    },
    handlerOpen(index) {
      if (index == "/accessGuide") {
        this.handlerSelect("/accessGuide");
        return false;
      }
    },
  },
};
</script>
<style lang="less">
.leftWrapper {
  .el-menu {
    border: none;
  }
  .el-submenu__title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    &:hover {
      background-color: #f6f8fa;
    }
  }
  .el-menu-item-group__title {
    padding: 0;
  }
  .el-menu-item {
    color: #606c7c;
    &:hover {
      background-color: #f6f8fa;
    }
    &.is-active {
      color: #409eff;
    }
  }
  .menuHome {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #303133;
    &:hover {
      background-color: #f6f8fa;
    }
  }
  .secondMenu {
    padding-left: 28px !important;
  }
}
</style>
