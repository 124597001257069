import NewsList from "@/pages/news/newsList.vue";
import NewsDetail from "@/pages/news/newsDetail.vue";
import NewsAdd from "@/pages/news/newsAdd.vue";
import NewsUpdate from "@/pages/news/newsUpdate.vue";
import NewsDelete from "@/pages/news/newsDelete.vue";

let routes = [
  {
    path: "/newsList",
    name: "newsList",
    component: NewsList,
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: NewsDetail,
  },
  {
    path: "/newsAdd",
    name: "newsAdd",
    component: NewsAdd,
  },
  {
    path: "/newsUpdate",
    name: "newsUpdate",
    component: NewsUpdate,
  },
  {
    path: "/newsDelete",
    name: "newsDelete",
    component: NewsDelete,
  },
];

export default routes;
