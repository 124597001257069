import CustomerList from "@/pages/customer/customerList.vue";
import CustomerDetail from "@/pages/customer/customerDetail.vue";
import CustomerAdd from "@/pages/customer/customerAdd.vue";
import CustomerUpdate from "@/pages/customer/customerUpdate.vue";
import CustomerDelete from "@/pages/customer/customerDelete.vue";

let routes = [
  {
    path: "/customerList",
    name: "customerList",
    component: CustomerList,
  },
  {
    path: "/customerDetail",
    name: "customerDetail",
    component: CustomerDetail,
  },
  {
    path: "/customerAdd",
    name: "customerAdd",
    component: CustomerAdd,
  },
  {
    path: "/customerUpdate",
    name: "customerUpdate",
    component: CustomerUpdate,
  },
  {
    path: "/customerDelete",
    name: "customerDelete",
    component: CustomerDelete,
  },
];

export default routes;
