<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="homeWrapper">
      <div class="title">绑定ECS</div>
      <div class="updateTime">更新时间：2024-04-07 14:25</div>
      <div class="content">
        <div>本文详细说明了在网站后台绑定ECS和解绑ECS的方法和步骤。</div>
        <div><strong>绑定 ECS：</strong></div>
        <div>
          绑定ECS有两种方式，一种是在网站后台进行绑定ECS，一种是通过在ECS侧绑定网站，两种方式均可实现网站和ECS的关联。
        </div>
        <div><strong>1.网站后台进行绑定ECS：</strong></div>
        <div style="padding-left: 20px">
          <div>
            点击<strong>开发者模式 > 绑定ECS</strong>开始绑定ECS
            IP，支持在网站后台内通过两种方式进行绑定ECS。
          </div>
          <ul class="contentList">
            <li><div>方式一：直接输入ECS IP；</div></li>
            <li>
              <div>
                方式二：通过网站的“管理员手机号码”查询当前网站可绑定的ECS IP；
              </div>
            </li>
          </ul>
          <img class="contentImg" src="@/assets/image/bindecs.png" />
          <div>成功绑定ECS IP后：</div>
          <ul class="contentList">
            <li>
              <div>
                <a href="appTemplate">应用模板</a>中的“微站镜像”应用自动安装到<a
                  href="myApp"
                  >我的应用</a
                >；
              </div>
            </li>
            <li><div>网站的“域名管理”菜单隐藏；</div></li>
            <li>
              <div>
                控制台首页-绑定域名：点击自动跳转到<a href="myApp">我的应用</a
                >；
              </div>
            </li>
            <li><div>控制台首页-域名：显示独立IP地址；</div></li>
            <li>
              <div>
                <a href="appTemplate">应用模板</a
                >中的应用方可进行“安装”、“下载”；
              </div>
            </li>
            <li>
              <div><a href="myApp">我的应用</a>方可进行上传操作；</div>
            </li>
          </ul>
        </div>
        <div><strong>2.ECS绑定网站</strong></div>
        <div style="padding-left: 20px">
          <div>1.访问产品激活页面</div>
          <ul class="contentList">
            <li><div>复制ECS公网IP；</div></li>
            <li>
              <div>浏览器访问“ECS公网IP”；</div>
            </li>
          </ul>
          <img class="contentImg" src="@/assets/image/bindecs1.png" />
          <div>2.设置管理员手机号（区分两种情况）</div>
          <ul class="contentList">
            <li>
              <div>
                情况一：当前阿里云实例ID下有已购买的云梦产品&已经成功设置了管理员手机号，则自动回显手机号；
              </div>
              <img class="contentImg" src="@/assets/image/bindecs2.png" />
            </li>
            <li>
              <div>
                情况二：当前阿里云实例ID下没有已购买的云梦产品，则需要手动填写手机号；
              </div>
              <img class="contentImg" src="@/assets/image/bindecs3.png" />
            </li>
          </ul>
          <div>3.绑定网站（两种情况）</div>
          <ul class="contentList">
            <li>
              <div>
                情况一：当前管理员手机号下存在已购买的云企/速美产品且尚未绑定ECSIP，自动回显，支持勾选进行绑定。
              </div>
              <img class="contentImg" src="@/assets/image/bindecs4.png" />
            </li>
            <li>
              <div>
                情况二：当前管理员手机号下不存在已购买的且尚未绑定ECSIP的云企/速美产品，此时需要手动输入网站的二级域名或订单编号进行绑定。
              </div>
              <img class="contentImg" src="@/assets/image/bindecs5.png" />
            </li>
          </ul>
          <div>4.完成激活</div>
          <div>
            完成激活后，自动进入会员中心登录页面“https://ac.clouddream.net/”。
          </div>
          <img class="contentImg" src="@/assets/image/bindecs6.png" />
        </div>
        <div><strong>解绑ECS：</strong></div>
        <div>
          支持ECS IP进行解绑，解绑ECS IP后，<a href="myApp">我的应用</a
          >下的所有应用均进行删除操作，恢复“域名管理”显示，历史绑定域名的应用均不能访问到已解绑的ECS服务器上。
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.homeWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .contentImg {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 24px;
  }
  .content div {
    font-size: 14px;
    color: #181818;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0 0 16px;
    word-break: break-word;
  }
  strong {
    font-weight: bold;
  }
  .contentList {
    padding-left: 25px;
    li {
      list-style-type: disc;
      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
  a {
    color: #409eff;
  }
}
</style>
