import '@/assets/styles/index.less'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import 'highlight.js/styles/github.css'
import '@/assets/styles/hljs-github.less'
import '@/assets/styles/hljs-light.less'
import hljs from "highlight.js";
// 增加自定义命令v-highlight
Vue.directive("highlight", function(el) {
  hljs.configure({
    ignoreUnescapedHTML: true
  })
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach(block => {
    hljs.highlightElement(block);
  });
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
