import http from '@/utils/request.js'

//获取接口调用历史
export function getApiCallHistory(params) {
    return http.get('/OpenApi/GetApiCallHistory', params)
}


//获取文章列表
export function getNewsList(params) {
    return http.get('/OpenNews/List', params)
}
//获取文章详情
export function getNewsDetail(params) {
    return http.get('/OpenNews/Detail', params)
}
//新增文章
export function newsAdd(params) {
    return http.post('/OpenNews/Add', params)
}
//编辑文章
export function newsUpdate(params) {
    return http.post('/OpenNews/Update', params)
}
//删除文章
export function newsDelete(params) {
    return http.post('/OpenNews/Delete', params)
}


//获取产品列表
export function getProductList(params) {
    return http.get('/OpenProduct/List', params)
}
//获取产品详情
export function getProductDetail(params) {
    return http.get('/OpenProduct/Detail', params)
}
//新增产品
export function productAdd(params) {
    return http.post('/OpenProduct/Add', params)
}
//编辑产品
export function productUpdate(params) {
    return http.post('/OpenProduct/Update', params)
}
//删除产品
export function productDelete(params) {
    return http.post('/OpenProduct/Delete', params)
}


//获取会员列表
export function getCustomerList(params) {
    return http.get('/OpenCustomer/List', params)
}
//获取会员详情
export function getCustomerDetail(params) {
    return http.get('/OpenCustomer/Detail', params)
}
//新增会员
export function customerAdd(params) {
    return http.post('/OpenCustomer/Add', params)
}
//编辑会员
export function customerUpdate(params) {
    return http.post('/OpenCustomer/Update', params)
}
//删除会员
export function customerDelete(params) {
    return http.post('/OpenCustomer/Delete', params)
}


//获取图片列表
export function getPictureList(params) {
    return http.get('/OpenPicture/List', params)
}
//获取图片详情
export function getPictureDetail(params) {
    return http.get('/OpenPicture/Detail', params)
}
//新增图片
export function pictureAdd(params) {
    return http.post('/OpenPicture/Add', params)
}
//编辑图片
export function pictureUpdate(params) {
    return http.post('/OpenPicture/Update', params)
}
//删除图片
export function pictureDelete(params) {
    return http.post('/OpenPicture/Delete', params)
}