<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="homeWrapper">
      <div class="title">开发者模式</div>
      <div class="content">
        <div class="summary">
          开发者模式主要用于满足开发者或高级用户的个性化需求。通过开发者模式，用户可个性化独立部署含网站在内的多方应用，实现深度定制和调用系统功能的效果。对接云梦API开放接口后，用户可以轻松将图片、新闻、产品及会员等数据集成到自己的应用程序或系统中，帮助用户更高效、灵活地管理IT资源和部署应用。
        </div>
        <div>
          开发者模式包含以下几个模块：绑定ECS、应用模板、我的应用、通用接口、AccessKey管理；
        </div>
        <div class="tipsWrapper">
          <div style="margin-right: 12px">
            <i class="el-icon-warning-outline"></i>
            <strong style="margin-left: 4px">说明</strong>
          </div>
          <div>
            <li style="margin-bottom: 8px">
              1.要想实现独立部署，需要在购买ECS时选择“云·原生建站-
              容器版”后，将ECS IP和网站进行绑定即可；
            </li>
            <li>
              2.ECS IP和网站绑定有两种方式，可通过浏览器输入ECS
              IP进入到激活页面绑定网站，也可通过网站内直接绑定ECS IP；
            </li>
          </div>
        </div>
        <div>
          为了让您更直观地体验通过开发者模式部署第三方应用后，实现对网站个性化定制效果，以绑定了ECS
          IP后的网站为前提，对单页面应用（Pages
          Browser）的场景使用进行示例说明。
        </div>
        <div>
          1.点击<strong>应用管理</strong>，找到应用Pages
          Browser，点击<strong>安装</strong>；
        </div>
        <img class="contentImg" src="@/assets/image/home1.png" />
        <div>
          2.安装应用成功后，点击<strong>我的应用</strong>，找到应用名称为“Pages
          Browser”，绑定一个阿里云域名，系统会自动为你进行解析并开启HTTPS；
        </div>
        <img class="contentImg" src="@/assets/image/home2.png" />
        <div>
          3.开启HTTPS成功后，点击所绑定的域名，进入到应用的登录页面，使用初始账号和密码登录到应用内；
        </div>
        <img class="contentImg" src="@/assets/image/home3.png" />
        <div>
          4.登录应用后，支持手动拖拽本地文件到当前应用，也支持本地上传文件，你可以上传任意你想实现的前端页面内容；
        </div>
        <img class="contentImg" src="@/assets/image/home4.png" />
        <div>5.点击html文件，可以在线编辑文件内容，保存即可生效；</div>
        <img class="contentImg" src="@/assets/image/home5.png" />
        <div>6.此时访问所绑定的域名，默认访问该应用内的index.html文件；</div>
        <img class="contentImg" src="@/assets/image/home6.png" />
        <div>7.点击设置，你可以在个人设置中更改当前应用的登录账号和密码；</div>
        <img class="contentImg" src="@/assets/image/home7.png" />
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.homeWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .contentImg {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 24px;
  }
  .content {
    .summary {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin: 32px 0;
    }
    div {
      font-size: 14px;
      color: #181818;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin: 0 0 16px;
      word-break: break-word;
    }
  }
  strong {
    font-weight: bold;
  }
  .contentList {
    padding-left: 25px;
    li {
      list-style-type: disc;
      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
  a {
    color: #409eff;
  }
  .tipsWrapper {
    display: flex;
    background-color: #f5f5f6;
    border-left: 2px solid #409eff;
    padding: 20px 0 4px 20px;
    i {
      font-size: 16px;
      color: #409eff;
    }
  }
}
</style>
