<template>
  <el-container style="height: 100%">
    <el-aside width="270px" style="border-right: 1px solid #eceef3">
      <LeftMenu />
    </el-aside>
    <router-view />
  </el-container>
</template>

<script>
import LeftMenu from "@/components/leftMenu.vue";

export default {
  components: {
    LeftMenu,
  },
};
</script>
<style lang="less"></style>
