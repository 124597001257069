<template>
  <el-container class="contentWrapper">
    <el-header height="80px" style="border: 1px solid #eceef3">
      <Header />
    </el-header>
    <el-container style="height: calc(100% - 80px)">
      <el-aside class="contentLeft" width="400px">
        <div class="formTitle">输入参数</div>
        <el-scrollbar class="formScroll">
          <el-form
            class="formWrapper"
            :model="formData"
            ref="argumentForm"
            :rules="rules"
            label-position="top"
          >
            <el-form-item prop="PageIndex" label="PageIndex 页码">
              <el-input-number
                v-model="formData.PageIndex"
                placeholder="请输入整型数字"
                controls-position="right"
                :step-strictly="true"
                :min="1"
              ></el-input-number>
            </el-form-item>
            <el-form-item prop="PageSize" label="PageSize 页大小">
              <el-input-number
                v-model="formData.PageSize"
                placeholder="请输入整型数字"
                controls-position="right"
                :step-strictly="true"
                :min="1"
              ></el-input-number>
            </el-form-item>
            <el-form-item prop="Keyword" label="Keyword 关键词">
              <el-input
                v-model="formData.Keyword"
                placeholder="请输入字符串"
              ></el-input>
            </el-form-item>
            <el-form-item prop="IsDeleted" label="IsDeleted 是否删除">
              <el-select
                v-model="formData.IsDeleted"
                placeholder="请选择布尔类型"
              >
                <el-option label="true" :value="true"> </el-option>
                <el-option label="false" :value="false"> </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div class="formFooter">
          <el-button @click="resetForm">清空</el-button>
          <el-button type="primary" @click="submitForm">发起调用</el-button>
        </div>
      </el-aside>
      <div class="tabsWrapper">
        <el-tabs v-model="tabsActive" @tab-click="tabsChange">
          <el-tab-pane name="document" label="文档">
            <el-scrollbar style="height: 100%">
              <RequestMethod method="GET" address="/OpenCustomer/List" />
              <div class="tableTitle">请求参数</div>
              <el-table :data="requestTableData" :border="true">
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="isRequired" label="必填" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
              <div class="tableTitle">响应参数</div>
              <el-table
                class="lastTable"
                :data="responseTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="160">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="result" label="调用结果">
            <el-scrollbar style="height: 100%">
              <pre
                v-show="responseData"
                v-highlight
                :key="timestep"
              ><code class="json" v-html="responseData"></code></pre>
              <div v-show="!responseData">
                <el-empty description="暂无调用结果"></el-empty>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="history" label="调用历史">
            <History ref="history" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { getCustomerList } from "@/api/index.js";
import Header from "@/components/header.vue";
import History from "@/components/history.vue";
import RequestMethod from "@/components/requestMethod.vue";
export default {
  components: {
    Header,
    History,
    RequestMethod,
  },
  data() {
    return {
      formData: {
        PageIndex: 1,
        PageSize: 10,
        Keyword: "",
        IsDeleted: "",
      },
      rules: {
        PageIndex: [
          { required: true, message: "请输入PageIndex", trigger: "blur" },
          { type: "number", message: "参数必须为整型数字" },
        ],
        PageSize: [
          { required: true, message: "请输入PageSize", trigger: "blur" },
          { type: "number", message: "参数必须为整型数字" },
        ],
      },
      tabsActive: "document",
      requestTableData: [
        {
          name: "PageIndex",
          type: "Integer",
          isRequired: "是",
          description: "页码",
          example: "1",
        },
        {
          name: "PageSize",
          type: "Integer",
          isRequired: "是",
          description: "分页大小",
          example: "10",
        },
        {
          name: "Keyword",
          type: "String",
          isRequired: "否",
          description: "关键词",
          example: "",
        },
        {
          name: "IsDeleted",
          type: "Boolean",
          isRequired: "否",
          description: "是否删除",
          example: "false",
        },
      ],
      responseTableData: [
        {
          name: "Data",
          type: "",
          description: "请求数据",
          example: "",
          children: [
            {
              name: "PageIndex",
              type: "Integer",
              description: "页码",
              example: "",
            },
            {
              name: "PageSize",
              type: "Integer",
              description: "分页大小",
              example: "",
            },
            {
              name: "TotalCount",
              type: "Integer",
              description: "会员总数量",
              example: "",
            },
            {
              name: "TotalPageCount",
              type: "Integer",
              description: "分页总数量",
              example: "",
            },
            {
              name: "List",
              type: "Object []",
              description: "会员数据列表",
              example: "",
              children: [
                {
                  name: "Id",
                  type: "Integer",
                  description: "会员Id",
                  example: "",
                },
                {
                  name: "UserName",
                  type: "String",
                  description: "会员名称",
                  example: "",
                },
                {
                  name: "RolesNames",
                  type: "String",
                  description: "角色名称",
                  example: "",
                },
                {
                  name: "CreatedDate",
                  type: "String",
                  description: "会员创建时间",
                  example: "",
                },
                {
                  name: "LastLoginDateUtc",
                  type: "String",
                  description: "上次登录时间",
                  example: "",
                },
                {
                  name: "IsDeleted",
                  type: "Boolean",
                  description: "是否删除",
                  example: "false",
                },
              ],
            },
          ],
        },
        {
          name: "IsSuccess",
          type: "Boolean",
          description: "请求是否成功",
          example: "true",
        },
        {
          name: "Msg",
          type: "String",
          description: "请求失败信息",
          example: "",
        },
        {
          name: "RequestId",
          type: "String",
          description: "请求ID",
          example: "",
        },
      ],
      responseData: "",
      timestep: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs["argumentForm"].validate((valid) => {
        if (valid) {
          getCustomerList(this.formData)
            .then((res) => {
              this.tabsActive = "result";
              this.timestep = Date.parse(new Date());
              this.responseData = res;
              if (res.IsSuccess) {
                this.$message({
                  message: "调用成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.Msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["argumentForm"].resetFields();
    },
    tabsChange() {
      if (this.tabsActive == "history") {
        this.$refs["history"].getApiHistory();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less"></style>
