<template>
  <el-scrollbar style="height: 100%; width: 100%">
    <div class="homeWrapper">
      <div class="title">我的应用</div>
      <div class="updateTime">更新时间：2024-04-07 14:25</div>
      <div class="content">
        <div>本文描述了我的应用的使用和说明。</div>
        <div>
          我的应用是应用管理的地方，用户在此处对应用进行域名设置，应用来源于两种方式：
        </div>
        <ul class="contentList">
          <li>
            <div>方式一：<a href="appTemplate">应用模板</a>的安装；</div>
          </li>
          <li>
            <div>方式二：通过本地上传.tar文件类型的文件，成功构建安装；</div>
          </li>
        </ul>
        <div><strong>操作说明</strong></div>
        <div>
          1.上传应用：点击<strong>上传应用</strong>，判断是否在上传应用时绑定过域名，该域名用于推送镜像到ECS之间的接口调用，有域名记录，则无需再次上传，域名解析成功后，显示上传应用的弹窗；
        </div>
        <img class="contentImg" src="@/assets/image/myApp1.png" />
        <ul class="contentList">
          <li>
            <div>确定上传后，首先进行应用构建；</div>
            <img class="contentImg" src="@/assets/image/myApp2.png" />
          </li>
          <li>
            <div>构建成功，进行安装：安装成功后，显示在应用列表中；</div>
          </li>
          <li>
            <div>构建失败：支持重试，或选择通过本地命令行的方式进行上传。</div>
          </li>
        </ul>
        <div class="tipsWrapper">
          <div style="margin-right: 12px">
            <i class="el-icon-warning-outline"></i>
            <strong style="margin-left: 4px">说明</strong>
          </div>
          <div>
            <li>1.镜像名称具有唯一性；</li>
            <li>2.本地文件：仅支持.tar文件类型；</li>
          </div>
        </div>
        <div>
          2.绑定：一个应用支持绑定多个域名，一个域名仅可以绑定在单个应用上，阿里云授权过的域名解析的记录有效期为一年，有效期内无需再次授权。
        </div>
        <img class="contentImg" src="@/assets/image/myApp3.png" />
        <ul class="contentList">
          <li>
            <div>
              域名绑定：自动进行一键解析，一键解析需要该域名所在的阿里云账号进行解析授权，同时该域名不存在其他的解析记录值，若存在可进行覆盖或删除其他记录值后继续进行一键解析。
            </div>
          </li>
        </ul>
        <div>3.解绑：解绑后，该域名将无法访问该应用。</div>
        <div>4.删除：删除应用后，所绑定的域名和应用自动解绑。</div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.homeWrapper {
  max-width: 1000px;
  padding: 56px;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #262626;
  }
  .updateTime {
    font-size: 14px;
    color: #999;
    margin: 18px 0;
  }
  .contentImg {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 24px;
  }
  .content div {
    font-size: 14px;
    color: #181818;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0 0 16px;
    word-break: break-word;
  }
  strong {
    font-weight: bold;
  }
  .contentList {
    padding-left: 25px;
    li {
      list-style-type: disc;
      &::marker {
        color: grey;
        font-size: 12px;
      }
    }
  }
  a {
    color: #409eff;
  }
  .tipsWrapper {
    display: flex;
    background-color: #f5f5f6;
    border-left: 2px solid #409eff;
    padding: 16px 0 0 16px;
    i {
      font-size: 16px;
      color: #409eff;
    }
  }
}
</style>
