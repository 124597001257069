<template>
  <div class="headerWrapper">
    <div class="inputWrapper">
      <div class="inputLabel">站点域名</div>
      <el-input
        placeholder="站点域名"
        v-model="secondDomain"
        :disabled="false"
        @change="secondDomainChange"
      >
      </el-input>
    </div>
    <div class="inputWrapper">
      <div class="inputLabel">AccessId</div>
      <el-input
        placeholder="AccessId"
        v-model="AccessId"
        @change="AccessIdChange"
      >
      </el-input>
    </div>
    <div class="inputWrapper">
      <div class="inputLabel">AccessKey</div>
      <el-input
        placeholder="AccessKey"
        v-model="AccessKey"
        @change="AccessKeyChange"
        show-password
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      secondDomain: "",
      AccessId: "",
      AccessKey: "",
    };
  },
  methods: {
    secondDomainChange() {
      let url = this.secondDomain;
      if (
        url.toLowerCase().indexOf("http:") == -1 &&
        url.toLowerCase().indexOf("https:") == -1
      ) {
        url = "//" + url;
      }
      this.$store.commit("setSecondDomain", url);

      if (url.toLowerCase().indexOf("http:") != -1) {
        if (location.protocol == "https:") {
          let newUrl =
            "http://" +
            location.host +
            location.pathname +
            "?secondDomain=" +
            url +
            "&AccessId=" +
            this.$store.state.AccessId +
            "&AccessKey=" +
            this.$store.state.AccessKey;
          location.replace(newUrl);
        }
      }
    },
    AccessIdChange() {
      this.$store.commit("setAccessId", this.AccessId);
    },
    AccessKeyChange() {
      this.$store.commit("setAccessKey", this.AccessKey);
    },
  },
  mounted() {
    if (this.$route.query.AccessId) {
      this.AccessId = this.$route.query.AccessId;
      this.AccessIdChange();
    } else {
      if (this.$store.state.AccessId) {
        this.AccessId = this.$store.state.AccessId;
      }
    }
    if (this.$route.query.AccessKey) {
      this.AccessKey = this.$route.query.AccessKey;
      this.AccessKeyChange();
    } else {
      if (this.$store.state.AccessKey) {
        this.AccessKey = this.$store.state.AccessKey;
      }
    }
    if (this.$route.query.secondDomain) {
      this.secondDomain = this.$route.query.secondDomain;
    } else {
      if (this.$store.state.secondDomain) {
        if (this.$store.state.secondDomain.slice(0, 2) == "//") {
          this.secondDomain = this.$store.state.secondDomain.slice(
            2,
            this.$store.state.secondDomain.length
          );
        } else {
          this.secondDomain = this.$store.state.secondDomain;
        }
      }
    }
    this.secondDomainChange();
  },
  //   watch: {
  //     "$route.query": {
  //       immediate: true,
  //       deep: true,
  //       handler(newValue, oldValue) {
  //         console.log(newValue);
  //       },
  //     },
  //   },
};
</script>
<style lang="less">
.headerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  .inputWrapper {
    width: 440px;
    max-width: 440px;
    display: flex;
    align-items: center;
    margin-right: 30px;
    .inputLabel {
      margin-right: 16px;
      color: #606c7c;
    }
    .el-input {
      flex: 1;
    }
  }
}
</style>
